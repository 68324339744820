























import UserListStatsViewModel from "@/common/viewModels/UserListStatsViewModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class UserListStatsComponent extends Vue {
    @Prop() private stats: UserListStatsViewModel;

    private entries: object[] = [];

    mounted() {
        this.updateEntries();
    }

    @Watch("stats") onStatsChange() {
        this.updateEntries();
    }

    private updateEntries() {
        this.entries = [];

        const keyPrefix = "component.userlistStats.";

        this.entries.push({
            title: keyPrefix + "totalEntries",
            value: this.stats.totalEntries
        });

        this.entries.push({
            title: keyPrefix + "totalEpisodes",
            value: this.stats.totalEpisodes
        });

        this.entries.push({
            title: keyPrefix + "averageRating",
            value: this.stats.averageRating
        });

        this.entries.push({
            title: keyPrefix + "daysWatched",
            value: this.stats.daysWatched
        });
    }
}
