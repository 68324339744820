


















import { Component, Vue } from "vue-property-decorator";
import ProfileBaseView from "@/views/account/profile/ProfileBaseView.vue";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import UserListStatsComponent from "@/components/account/profile/UserListStatsComponent.vue";
import UserListStatsViewModel from "@/common/viewModels/UserListStatsViewModel";

@Component({
    components: {
        ProfileBaseView,
        UserListStatsComponent
    }
})
export default class OverviewView extends Vue {
    private animeStats: UserListStatsViewModel = new UserListStatsViewModel();

    private userStatsDataContext = new UserListDataContext();

    mounted() {
      this.getUserStats();
    }

    private getUserStats() {
        const userName = this.$route.params.userName;

        if (userName) {
            this.userStatsDataContext.getAnimeUserListStats(userName).then(x => {
                if (x.successfully && x.data) {
                    this.animeStats = x.data;
                }
            });
        }
    }
}
