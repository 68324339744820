



























import { Component, Vue } from "vue-property-decorator";
import TabsHubTab from "@/common/models/TabsHubTab";
import TabsHubComponent from "@/components/global/TabsHubComponent.vue";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import UserDataContext from "@/dataContexts/UserDataContext";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import { UserInfo } from "@sokkuri/common";

@Component({
    components: {
        TabsHubComponent,
        SpinnerComponent
    }
})
export default class ProfileBaseView extends Vue {
    private userDataContext: UserDataContext = new UserDataContext();

    private loading = false;

    private tabs: TabsHubTab[] = [];
    private userInfo: UserInfo = new UserInfo();

    created() {
        const userName = this.$route.params.userName;

        this.tabs = [
            new TabsHubTab({ label: TranslationUtils.translate("view.profile.tabsHub.overview"), url: `/profile/${userName}` }),
            new TabsHubTab({ label: TranslationUtils.translate("view.profile.tabsHub.animeList"), url: `/profile/${userName}/animeList` })
        ];

        if (userName) {
            this.loading = true;

            this.userDataContext.getUserInfo(userName).then(x => {
                if (x.successfully && x.data) {
                    this.userInfo = x.data;
                }
            }).finally(() => this.loading = false);
        }
    }
}
